import { FC, ReactNode, useState } from 'react';
import { Student } from '../../../models/student.model';
import { useGet } from '../../../hooks/useApi';
import { Modal, Skeleton } from 'antd';
import { Table } from 'react-bootstrap';

import './EnrollmentsDiv.css';
import moment from 'moment';
import { Camp } from '../../../models/camp.model';
import { StudentProgram } from '../../../models/student-program.model';
import { StudentSchedule } from '../../../models/student-schedule.model';
import { NSDCamp } from '../../../models/nsd-camp.model';

interface EnrollmentsDivProps {
  student: Partial<Student> & { id: number };
}

type Response = {
  campEnrollments: Camp[];
  preschoolEnrollments: StudentProgram[];
  newPreschoolEnrollments: StudentSchedule[];
  galaxyEnrollments: StudentSchedule[];
  upcomingScheduleChanges: StudentProgram[];
  summerEnrollments: StudentSchedule[];
  elopEnrollments: StudentSchedule[];
  novaEnrollments: StudentSchedule[];
  NSDEnrollments: NSDCamp[];
};

const EnrollmentsDiv: FC<EnrollmentsDivProps> = ({ student }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalUrl, setEditModalUrl] = useState(
    'https://accounts.starsacramento.org/contact/#general'
  );
  const enrollments = useGet<Response>(
    `/v1/auth/dashboard/student-enrollments/student/${student.id}`
  );

  const openModal = (
    e: React.MouseEvent,
    url = 'https://accounts.starsacramento.org/contact/#general'
  ) => {
    e.preventDefault();
    setEditModalUrl(url);
    setEditModalVisible(true);
  };

  const getScheduleString = (object: any) => {
    const scheduleArray = [];

    if (object.monday || object.m) {
      scheduleArray.push('M');
    }

    if (object.tuesday || object.t) {
      scheduleArray.push('T');
    }
    if (object.wednesday || object.w) {
      scheduleArray.push('W');
    }
    if (object.thursday || object.th) {
      scheduleArray.push('Th');
    }
    if (object.friday || object.f) {
      scheduleArray.push('F');
    }

    return scheduleArray.join(', ');
  };

  const getNSDScheduleString = (days: { date: string | Date }[]): string => {
    const weekdayAbbreviations = ['M', 'T', 'W', 'Th', 'F'];
    const scheduleSet = new Set<string>();

    for (const object of days) {
      const weekday = moment(object.date).weekday();
      if (weekday >= 0 && weekday < 5) {
        scheduleSet.add(weekdayAbbreviations[weekday - 1]);
      }
    }

    return Array.from(scheduleSet).join(', ');
  };

  const noEnrollments = (data: Response) => {
    for (const index in data) {
      // @ts-ignore
      if (data[index].length > 0) {
        return false;
      }
    }

    return true;
  };

  const ScheduleDiv: FC<{
    title: string;
    url?: string;
    children: ReactNode;
  }> = ({ title, url, children }) => {
    return (
      <div className={'enrollments-div mt-3'}>
        <div className={'pb-2'}>
          <table className={'w-100'}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <h5 className={'p-0 m-0'}>{title}</h5>
              </td>
              <td className={'text-right'} style={{ verticalAlign: 'middle' }}>
                <small>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className={'btn btn-sm btn-outline-primary'}
                    style={{ fontSize: '11px' }}
                    href="#"
                    onClick={(e) => openModal(e, url)}
                  >
                    Request Schedule Change
                  </a>
                </small>
              </td>
            </tr>
          </table>
        </div>

        {children}
      </div>
    );
  };

  const ScheduleDefaultLayout = (
    title: string,
    array: StudentSchedule[],
    url?: string
  ) => {
    if (!array.length) {
      return null;
    }

    return (
      <ScheduleDiv title={title} url={url}>
        <Table className={'text-center'}>
          <thead>
            <tr>
              <th>Program</th>
              <th>School</th>
              <th>Title</th>
              <th style={{ minWidth: '120px' }}>Days</th>
            </tr>
          </thead>
          <tbody>
            {array.map((studentProgram) => (
              <tr key={studentProgram.Schedule?.ProgramListing?.id}>
                <td style={{ fontWeight: 700 }}>
                  {studentProgram.Schedule?.ProgramListing?.group}
                </td>
                <td>{studentProgram.Schedule?.ProgramListing?.subGroup}</td>
                <td>{studentProgram.Schedule?.name}</td>
                <td>{getScheduleString(studentProgram)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScheduleDiv>
    );
  };

  const ScheduleGalaxy = () =>
    ScheduleDefaultLayout(
      'STAR Galaxy',
      enrollments.data?.galaxyEnrollments || []
    );

  const ScheduleElop = () =>
    ScheduleDefaultLayout(
      'STAR ELO-P',
      enrollments.data?.elopEnrollments || []
    );

  const ScheduleNova = () =>
    ScheduleDefaultLayout('STAR Nova', enrollments.data?.novaEnrollments || []);

  const ScheduleSummer = () =>
    ScheduleDefaultLayout(
      'STAR Summer Camp',
      enrollments.data?.summerEnrollments || [],
      'https://accounts.starsacramento.org/contact/#support'
    );

  // Custom Layouts

  const ScheduleUpcomingGalaxy = () => {
    const upcomingScheduleChanges =
      enrollments.data?.upcomingScheduleChanges || [];

    if (!upcomingScheduleChanges.length) {
      return null;
    }

    return (
      <ScheduleDiv title={'Upcoming STAR Galaxy Schedule Changes'}>
        <Table>
          <thead>
            <tr>
              <th>Schedule</th>
              <th style={{ minWidth: '120px' }}>Days</th>
              <th>Change</th>
              <th>Effective Date</th>
            </tr>
          </thead>
          <tbody>
            {upcomingScheduleChanges.map((studentProgram) => (
              <tr key={studentProgram.Program?.id}>
                <td style={{ fontWeight: 700 }}>
                  {`${studentProgram.Program?.title} @ ${studentProgram.Program?.Location?.School?.name}`}
                </td>
                <td>{getScheduleString(studentProgram)}</td>
                <td>{studentProgram.scheduleStart ? 'starting' : 'ending'}</td>
                <td>
                  {studentProgram.scheduleStart
                    ? moment(studentProgram.scheduleStart).format('MM/DD/YYYY')
                    : moment(studentProgram.disenrolled).format('MM/DD/YYYY')}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScheduleDiv>
    );
  };

  const ScheduleCamp = () => {
    const campEnrollments = enrollments.data?.campEnrollments || [];

    if (!campEnrollments.length) {
      return null;
    }

    return (
      <ScheduleDiv title={'STAR Non-School Day Camps'}>
        <Table>
          <thead>
            <tr>
              <th>Camp</th>
              <th>Camp Location</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {campEnrollments.map((studentProgram) => (
              <tr key={`campEnrollments` + studentProgram.id}>
                <td style={{ fontWeight: 700 }}>{studentProgram.name}</td>
                <td>studentProgram.HostSchool.name</td>
                <td>
                  {studentProgram.CampDays?.length
                    ? moment(studentProgram.CampDays[0].date).format(
                        'MM/DD/YYYY'
                      )
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScheduleDiv>
    );
  };

  const SchedulePreschool = () => {
    const preschoolEnrollments = enrollments.data?.preschoolEnrollments || [];
    const newpreschoolEnrollments =
      enrollments.data?.newPreschoolEnrollments || [];

    if (!preschoolEnrollments.length && !newpreschoolEnrollments.length) {
      return null;
    }

    return (
      <ScheduleDiv title={'STAR Preschool'}>
        <Table>
          <thead>
            <tr>
              <th>Schedule</th>
            </tr>
          </thead>
          <tbody>
            {preschoolEnrollments.map((studentProgram) => (
              <tr key={studentProgram.Program?.id}>
                <td style={{ fontWeight: 700 }}>
                  {`${studentProgram.Program?.title} @ ${studentProgram.Program?.Location?.School?.name}`}
                </td>
              </tr>
            ))}
            {newpreschoolEnrollments.map((studentProgram) => (
              <tr key={studentProgram.Schedule?.ProgramListing?.id}>
                <td style={{ fontWeight: 700 }}>
                  {`${studentProgram.Schedule?.ProgramListing?.title} @ ${studentProgram.Schedule?.ProgramListing?.Location?.School?.name}`}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScheduleDiv>
    );
  };

  const ScheduleNSD = () => {
    const NSDEnrollments = enrollments.data?.NSDEnrollments || [];

    if (!NSDEnrollments.length) {
      return null;
    }

    return (
      <ScheduleDiv
        title={'NSD Camps'}
        url={'https://accounts.starsacramento.org/contact/#support'}
      >
        <Table className={'text-center'}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Located At</th>
              <th style={{ minWidth: '120px' }}>Days</th>
            </tr>
          </thead>
          <tbody>
            {NSDEnrollments.map((studentProgram: any) => (
              <tr key={studentProgram.Schedule?.ProgramListing?.id}>
                <td>{studentProgram?.name}</td>
                <td>{studentProgram.School?.name}</td>
                <td>{getNSDScheduleString(studentProgram?.NSDCampDays)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScheduleDiv>
    );
  };

  if (enrollments.isLoading) {
    return <Skeleton active />;
  }

  if (enrollments.data && noEnrollments(enrollments.data)) {
    return (
      <div className="grid-x grid-margin-x grid-margin-y">
        <div className="cell large-auto">
          <div className="table-scroll pt-2">
            <h5 className={'m-0 p-0'}>Student Has No Current Enrollments</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ScheduleGalaxy />
      <ScheduleUpcomingGalaxy />
      <ScheduleElop />
      <ScheduleCamp />
      <ScheduleNova />
      <SchedulePreschool />
      <ScheduleSummer />
      <ScheduleNSD />

      <Modal
        title={'Schedule Change Request'}
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        {editModalUrl ==
        'https://accounts.starsacramento.org/contact/#general' ? (
          <>
            <p className={'p-0 mb-2'}>
              To change this schedule, you will need to make a request through
              the contact form.
            </p>
            <p>
              Be sure to select the correct department and school when making
              your request.
            </p>
          </>
        ) : (
          <>
            <p>
              To change this schedule, you will need to make a request with STAR
              tech support through the contact form.
            </p>
          </>
        )}

        <hr className={'p-0 mt-3 mb-4'} />

        <a
          className="btn btn-primary w-100"
          href={editModalUrl}
          target={'_blank'}
          rel="noreferrer"
        >
          Go To Contact Form
        </a>
      </Modal>
    </>
  );
};

export default EnrollmentsDiv;
