import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StarLogo from '../../assets/images/star-logo-ver-8.png';

import './Footer.css';

declare let google: any;
const Footer = () => {
  useEffect(() => {
    try {
      new google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <footer
      style={{
        backgroundColor: '#1f9bd6',
        color: 'white',
        padding: '20px 0',
        paddingBottom: '4px',
      }}
    >
      <Container fluid>
        <Row>
          <Col md={2} className="text-center">
            <img
              src={StarLogo} // Substitua pelo URL do logo
              alt="STAR Education"
              style={{
                width: '150px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </Col>
          <Col md={8}>
            <p style={{ paddingBottom: '8px' }}>
              <a
                href={'https://starsacramento.org/programs/galaxy'}
                target="_blank"
                rel="noreferrer"
              >
                STAR Galaxy
              </a>
            </p>
            <p style={{ paddingBottom: '8px' }}>
              <a
                href={'https://starsacramento.org/programs/nova/'}
                target="_blank"
                rel="noreferrer"
              >
                STAR Nova
              </a>
            </p>
            <p style={{ paddingBottom: '8px' }}>
              <a
                href={'https://starsacramento.org/programs/nsd-camps/'}
                target="_blank"
                rel="noreferrer"
              >
                STAR Non-School Day Camps
              </a>
            </p>
            <p style={{ paddingBottom: '8px' }}>
              <a
                href={'https://starsacramento.org/programs/summer-camp/'}
                target="_blank"
                rel="noreferrer"
              >
                STAR Summer Camps
              </a>
            </p>
          </Col>
          <Col md={2}>
            <p>Translate:</p>
            <div id="google_translate_element"></div>
          </Col>
        </Row>

        <Row>
          <Col
            md={12}
            className="text-center d-flex flex-column justify-content-end"
            style={{ fontSize: '60%' }}
          >
            <p>Copyright 2024 - STAR Sacramento - A Division of STAR Inc.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
