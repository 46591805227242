import React, { FC, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Button, Form, Input, Skeleton } from 'antd';
import { useLoginMutation } from '../../../hooks/useLoginMutation';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from '../../../hooks/useFetchUser';

const LoginDiv: FC = () => {
  const navigate = useNavigate();
  const loginMutation = useLoginMutation();
  const user = useFetchUser();

  const goToPasswordReset = (ev: any) => {
    ev.preventDefault();
    window.location.href = 'https://accounts.starsacramento.org/reset/';
  };

  const handleLogin = (values: { username: string; password: string }) => {
    localStorage.setItem('auth', JSON.stringify(values));
    loginMutation.mutate(values);
  };

  useEffect(() => {
    if (user.data?.id) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <>
      {(loginMutation.isLoading || user.isLoading) && (
        <Skeleton
          active
          loading={loginMutation.isLoading}
          paragraph={{ rows: 4 }}
        />
      )}
      {!loginMutation.isLoading && !user.isLoading && (
        <Card style={{ paddingBottom: 0 }}>
          <Card.Header>
            <Card.Title>Login</Card.Title>
          </Card.Header>
          <Card.Body className="pt-4">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="username"
                rules={[
                  { required: true, message: 'Please input your email!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <div>
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#703895' }}
                    block
                    size="large"
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </div>
                <div className="pt-3">
                  <Button
                    onClick={goToPasswordReset}
                    type="link"
                    block
                    style={{ color: '#703895' }}
                  >
                    Forgot Your Password?
                  </Button>
                </div>
              </Form.Item>

              {loginMutation.isError && (
                <div className={'alert alert-danger'}>
                  <b>Error: </b>{' '}
                  {loginMutation.error?.response?.data?.message ||
                    'An error occurred'}
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default LoginDiv;
