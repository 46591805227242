import { FC, useEffect, useState } from 'react';
import PageTitle from '../../layout/PageTitle/PageTitle';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { useGet } from '../../hooks/useApi';
import { NSDCamp } from '../../models/nsd-camp.model';
import { Button, Result, Select, Skeleton } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Student } from '../../models/student.model';
import { UserOutlined } from '@ant-design/icons';
import PaymentModal from './components/PaymentModal';

type CampWithAllowedStudents = NSDCamp & { allowedStudents: Student[] };

const NonSchoolDayCampEnrollment: FC = () => {
  const upcoming = useGet<CampWithAllowedStudents[]>(
    '/v1/auth/dashboard/nsd-camps/upcoming'
  );
  const [camps, setCamps] = useState<CampWithAllowedStudents[]>([]);
  const [camp, setCamp] = useState<CampWithAllowedStudents | null>(null);
  const [campId, setCampId] = useState<number | null>(null);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

  const chooseCamp = (id: number) => {
    setCampId(id);
    setCamp(camps.find((camp) => camp.id === id) || null);
  };

  useEffect(() => {
    const upcomingcamps = upcoming.data || [];
    setCamps(upcomingcamps);

    if (upcomingcamps.length === 1) {
      setCamp(upcomingcamps[0]);
      setCampId(upcomingcamps[0].id);
    }
  }, [upcoming.data]);

  const CampDiv = () => {
    if (!camp) {
      return null;
    }

    return (
      <>
        <div
          className={'mt-4 mb-4'}
          style={{
            backgroundColor: '#eadef3',
            padding: '1rem',
            border: '1px solid rgba(10,10,10,.25)',
            fontSize: '16px',
          }}
        >
          <p className={'mb-3'}>
            Now select an eligible student to view or enroll.
          </p>
          <p>
            Camp eligibility is based on current student enrollment. If you have
            students that are not showing up here, that you believe should be,
            please contact your STAR Site.
          </p>
        </div>

        <Row>
          <Col md={6}>
            <p
              className={'mb-3'}
              dangerouslySetInnerHTML={{
                __html: camp.description.replaceAll(
                  '\n',
                  '</p><p class="mb-3">'
                ),
              }}
            />
          </Col>
          <Col md={6}>
            <p className={'mb-3'}>
              <b>Students:</b>
            </p>

            {camp.allowedStudents.map((student) => (
              <div key={`div-key-${student.id}`} className={'pb-3'}>
                <Button
                  onClick={() => setSelectedStudent(student)}
                  size={'large'}
                  block={true}
                  type={'primary'}
                  key={student.id}
                  icon={<UserOutlined />} // Add the person icon here
                >
                  {student.firstName} {student.lastName}
                </Button>
              </div>
            ))}
          </Col>
        </Row>
      </>
    );
  };

  if (upcoming.isLoading) {
    return (
      <SidebarPage>
        <PageTitle>Non School Day Camp Enrollment</PageTitle>
        <Skeleton active />
      </SidebarPage>
    );
  }

  if (upcoming.isError) {
    return (
      <SidebarPage>
        <PageTitle>Non School Day Camp Enrollment</PageTitle>
        <Result
          status="500"
          title="Error"
          subTitle="Error while loading the page"
          extra={
            <Button onClick={() => upcoming.refetch()} type="primary">
              Try Again
            </Button>
          }
        />
      </SidebarPage>
    );
  }

  return (
    <SidebarPage>
      <PageTitle>Non School Day Camp Enrollment</PageTitle>

      <div
        style={{
          backgroundColor: '#eadef3',
          padding: '1rem',
          border: '1px solid rgba(10,10,10,.25)',
          fontSize: '16px',
        }}
      >
        <table className={'w-100'}>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>
              First select a currently enrolling camp from this list.
            </td>
            <td>
              {camps.length > 0 && (
                <Select
                  defaultValue={campId}
                  loading={upcoming.isLoading}
                  size={'large'}
                  placeholder={'Select a camp'}
                  onChange={chooseCamp}
                  style={{ width: '100%' }}
                >
                  {camps.map((camp) => (
                    <Select.Option key={camp.id} value={camp.id}>
                      {camp.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </td>
          </tr>
        </table>
      </div>

      <CampDiv />

      {camp && selectedStudent && (
        <PaymentModal
          id={camp.id}
          student={selectedStudent}
          visible={true}
          onVisibleChange={() => setSelectedStudent(null)}
        />
      )}
    </SidebarPage>
  );
};

export default NonSchoolDayCampEnrollment;
